/* ************************************************************************
   ************************************************************************

    COMMON SETTINGS

   ************************************************************************
   ************************************************************************ */
/* ------------------------------
    RESPONSIVE SETTINGS
  ------------------------------ */
/* ------------------------------
    FONT
  ------------------------------ */
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100..900&family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/* ------------------------------
    COLOR SETTINGS
  ------------------------------ */
/* ------------------------------
    STYLE SETTINGS
  ------------------------------ */
svg {
  fill: currentColor;
}

/* ------------------------------
    MIXIN
  ------------------------------ */
.loader_body {
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: fixed;
  inset: 0px;
}

.default_loader_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
}

.animation_wrap {
  position: relative;
  height: auto;
  width: 40%;
  max-width: 200px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oshi_img {
  position: absolute;
  width: 75%;
  height: auto;
  border-radius: 50%;
  aspect-ratio: 1;
  overflow: hidden;
  animation: imgloader 4s ease-in-out infinite;
}
.oshi_img img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
@keyframes imgloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  55% {
    transform: scale(0);
    opacity: 1;
  }
  65% {
    transform: scale(1.2);
    opacity: 1;
  }
  70% {
    transform: scale(1);
  }
  85% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.welcome_title {
  margin-top: 1rem;
  width: 100%;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
  line-height: 1;
}
.welcome span {
  display: inline-block;
  opacity: 0;
  animation: bounceText 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}
.welcome span:nth-child(1) {
  animation-delay: 2000ms;
}
.welcome span:nth-child(2) {
  animation-delay: 2050ms;
}
.welcome span:nth-child(3) {
  animation-delay: 2100ms;
}
.welcome span:nth-child(4) {
  animation-delay: 2150ms;
}
.welcome span:nth-child(5) {
  animation-delay: 2200ms;
}
.welcome span:nth-child(6) {
  animation-delay: 2250ms;
}
.welcome span:nth-child(7) {
  animation-delay: 2300ms;
}
.welcome span:nth-child(8) {
  animation-delay: 2350ms;
}
@keyframes bounceText {
  from {
    opacity: 0;
    transform: translateY(100px) scale(0.2, 0.2);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}/*# sourceMappingURL=ImgLoader.module.css.map */