/* ************************************************************************
   ************************************************************************

    COMMON SETTINGS

   ************************************************************************
   ************************************************************************ */
/* ------------------------------
    RESPONSIVE SETTINGS
  ------------------------------ */
/* ------------------------------
    FONT
  ------------------------------ */
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100..900&family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/* ------------------------------
    COLOR SETTINGS
  ------------------------------ */
/* ------------------------------
    STYLE SETTINGS
  ------------------------------ */
svg {
  fill: currentColor;
}

/* ------------------------------
    MIXIN
  ------------------------------ */
.loader_body {
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: fixed;
  inset: 0px;
}

.jee_loader_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.animation_wrap {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  pointer-events: none;
}/*# sourceMappingURL=SplashLoader.module.css.map */